import { onMounted, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { showLoading, hideLoading, showToast } from "@/utils/utils";
import { pageUpi, saveupi, saveAccount } from "@/api/account";
import { upiSuffixes } from "@/mock/upiSuffixes";
export default {
  name: "mobikwik",

  setup() {
    const router = useRouter();
    const route = useRoute(); // 获取当前路由对象

    const appCode = route.query.appCode || ""; // 从路由参数中获取 appCode

    const activeStep = ref(2); // 初始步骤

    const phone = ref(""); // 存储电话号码

    const otp = ref(""); // 存储 OTP

    const isSending = ref(false); // OTP 发送状态

    const countdown = ref(0); // OTP 倒计时

    const upiLeft = ref(""); // 用户输入的 UPI 账户名

    const upiRightInput = ref(""); // 用户输入的 UPI 后缀

    const filteredSuffixes = ref([]); // 用于存储过滤后的 UPI 后缀

    const isDropdownVisible = ref(true); // 控制下拉列表的显示状态

    const upiAccounts = ref([]); // 存储已有的UPI账户

    const onClickLeft = () => {
      router.back(); // 返回上一页
    }; // 启动倒计时函数


    const startCountdown = () => {
      countdown.value = 60; // 设置倒计时为 60 秒

      const interval = setInterval(() => {
        if (countdown.value > 0) {
          countdown.value--;
        } else {
          clearInterval(interval); // 倒计时结束，停止计时
        }
      }, 1000);
    }; // 模拟发送 OTP 的函数


    const sendOtp = () => {
      if (countdown.value > 0 || isSending.value) return;

      if (!phone.value || phone.value.length !== 10) {
        showToast("Please enter a valid phone number.");
        return;
      }

      isSending.value = true;
      setTimeout(() => {
        isSending.value = false;
        startCountdown();
      }, 2000); // 模拟发送 OTP 的延迟时间
    }; // "Previous" 按钮逻辑


    const onPrevious = () => {
      if (activeStep.value > 0) {
        activeStep.value--; // 返回上一步
      }
    }; // 完成操作


    const onFinish = () => {
      if (!otp.value || otp.value.length !== 6) {
        showToast("Please enter a valid 6-digit OTP.");
        return;
      }

      showToast("Process completed successfully!");
      router.push("/nextPage");
    }; // 处理输入框内容变化的逻辑


    const handleInputChange = () => {
      const inputValue = upiRightInput.value.trim().toLowerCase();
      console.log("当前输入的值：", inputValue); // 日志输出当前输入值

      if (inputValue) {
        // 如果输入框中有内容，则根据输入值过滤后缀列表
        filteredSuffixes.value = upiSuffixes.filter(suffix => suffix.toLowerCase().includes(inputValue));
        console.log("过滤后的结果：", filteredSuffixes.value); // 日志输出过滤后的结果

        isDropdownVisible.value = true; // 显示下拉框
      } else {
        // 如果输入框为空，则隐藏下拉框
        isDropdownVisible.value = false;
      }
    }; // 选择下拉列表中的一个 UPI 后缀


    const selectSuffix = suffix => {
      upiRightInput.value = suffix;
      isDropdownVisible.value = false; // 隐藏下拉列表

      filteredSuffixes.value = []; // 清空过滤后的 UPI 后缀列表
    }; // 当输入框失去焦点时延迟隐藏下拉列表


    const hideDropdownWithDelay = () => {
      setTimeout(() => {
        isDropdownVisible.value = false;
      }, 200);
    }; // 第二步保存账户信息的逻辑


    const submitAccount = async () => {
      showLoading("Saving Account..."); // 显示加载中的 Toast

      try {
        const response = await saveAccount({
          appCode: appCode,
          appLoginName: phone.value,
          otp: otp
        });
        hideLoading(); // 请求完成后关闭加载中的 Toast

        if (response.code === 1) {
          showToast("Account saved successfully!");
          activeStep.value = 2; // 如果保存成功，进入下一步
        } else {
          showToast(response.msg || "Failed to save account.");
        }
      } catch (error) {
        hideLoading(); // 请求失败时关闭加载中的 Toast

        console.error("Error saving account:", error);
        showToast("Error saving account.");
      }
    }; // 提交 UPI 信息


    const submitUpi = async () => {
      if (!upiLeft.value || !upiRightInput.value) {
        showToast("Please enter a valid UPI name and suffix.");
        return;
      }

      const upi = `${upiLeft.value}@${upiRightInput.value}`;
      showLoading("Saving UPI..."); // 显示加载中的 Toast

      try {
        const response = await saveupi({
          appId: appCode,
          upi: upi
        });
        hideLoading(); // 请求完成后关闭加载中的 Toast

        if (response.code === 1) {
          showToast("UPI saved successfully!");
        } else {
          showToast(response.msg || "Failed to save UPI.");
        }
      } catch (error) {
        hideLoading(); // 请求失败时关闭加载中的 Toast

        console.error("Error saving UPI:", error);
        showToast("Error saving UPI.");
      }
    }; // "Next" 按钮逻辑


    const onNextStep = () => {
      if (activeStep.value === 1) {
        submitAccount(); // 如果是第二步，调用 submitAccount 方法保存账户信息
      } else if (activeStep.value === 0 && (!phone.value || phone.value.length !== 10)) {
        showToast("Please enter a valid phone number.");
      } else {
        activeStep.value++; // 进入下一步
      }
    };

    const loadUpiAccounts = async () => {
      showLoading("Loading UPI accounts...");

      try {
        const response = await pageUpi({
          appId: appCode
        });

        if (response.code === 1) {
          upiAccounts.value = response.data.records.map(account => ({
            upiName: account.upiName,
            upiId: account.upiId
          }));
        }
      } catch (error) {
        showToast("Error loading UPI accounts.");
      } finally {
        hideLoading();
      }
    };

    const selectUpiAccount = account => {
      upiLeft.value = account.upiName;
      upiRightInput.value = account.upiId;
    };

    onMounted(() => {
      loadUpiAccounts();
    });
    return {
      phone,
      otp,
      isSending,
      countdown,
      activeStep,
      onClickLeft,
      sendOtp,
      onNextStep,
      onPrevious,
      onFinish,
      upiLeft,
      upiRightInput,
      filteredSuffixes,
      isDropdownVisible,
      handleInputChange,
      selectSuffix,
      hideDropdownWithDelay,
      submitUpi,
      submitAccount,
      upiAccounts
    };
  }

};