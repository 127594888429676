export const upiSuffixes = [
    "icici",
    "hdfcbank",
    "axisbank",
    "okhdfcbank",
    "okicici",
    "oksbi",
    "paytm",
    "ybl",
    "upi",
    "okaxis",
    "okyesbank",
    "dbs",
    "idbi",
    "barodampay",
    "federal",
    "indus",
    "kotak",
    "unionbank",
    "upiuser",
    "aubank",
    "canarabank",
    "csbpay",
    "lvb",
    "dbs",
    "iob",
    "kbl",
    "psb",
    "rbl",
    "sc",
    "saraswat"
];
